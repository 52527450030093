import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import { Locker } from "@/grpc/code-gen/locker_pb";
const infra = new Infrastructure();
import useNotification from "@/composables/useNotification";
import { LockerServiceLocker } from "@/grpc/code-gen/db_pb";
const { notify } = useNotification();

export default {
  namespaced: true,
  state: {
    allLockers: [] as Locker.AsObject[],
    selectedLocker: {} as Locker.AsObject,
    currentLockers: [] as Locker.AsObject[],
    merusoftLockers: [] as LockerServiceLocker.AsObject[],
    toggleMapLocker: 1
  },
  actions: {
    async getAllLockerList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.locker
          .GetAllLockerList()
          .then((response) => {
            if (response) {
              s.commit("addAllLockerList", response.lockerList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    async getByLockerCabinetIdLockerList(
      s: any,
      lockerCabinetId: string
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.locker
          .SelLockerCabinetIdLockers(lockerCabinetId)
          .then((response) => {
            if (response) {
              s.commit("addCurrentLockers", response.lockerList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    async getByMerusoftLockerCabinetIdLockerList(
      s: any,
      lockerCabinetId: string
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.locker
          .SelMerusoftLockerCabinetIdLockers(String(lockerCabinetId))
          .then((response) => {
            if (response) {
              s.commit("addMerusoftLockers", response.lockerservicelockerList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    async openLocker(s: any, locker: Locker.AsObject): Promise<void> {
      try {
        await infra.locker.OpenLocker(String(locker.smartofficeid));
        notify(`Дверца открыта`, "success");
      } catch (error) {
        console.log(error);
        notify(`Не удалось открыть ячейку ${locker.name}`, "error");
      }
    },
    addLocker(state: any, payload: Locker.AsObject): void {
      infra.locker
        .AddLocker(payload)
        .then((response) => {
          console.log("add locker success ", response);
          state.dispatch("getAllLockerList");
        })
        .catch((error) => {
          console.log("add locker failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось создать ячейку ${payload.name}`, "error");
        });
    },
    updLocker(state: any, payload: Locker.AsObject): void {
      infra.locker
        .UpdLocker(payload)
        .then((response) => {
          console.log("upd locker success  ", response);
          state.dispatch("getAllLockerList");
        })
        .catch((error) => {
          console.log("upd locker failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось сохранить ячейку ${payload.name}`, "error");
        });
    },
    delLocker(state: any, payload: Locker.AsObject): void {
      infra.locker
        .DelLocker(payload)
        .then((response) => {
          console.log("del locker success  ", response);
          state.dispatch("getAllLockerList");
        })
        .catch((error) => {
          console.log("del locker failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось удалить ячейку ${payload.name}`, "error");
        });
    }
  } as any,
  mutations: {
    addAllLockerList(state: any, arr: Locker[]) {
      state.allLockers = arr;
    },
    addCurrentLockers(state: any, arr: Locker[]) {
      state.currentLockers = arr;
    },
    addMerusoftLockers(state: any, arr: Locker[]) {
      state.merusoftLockers = arr;
    },
    addCurrentLocker(state: any, locker: Locker) {
      state.currentLockers.push(locker);
    },
    setLocker(state: any, locker: Locker) {
      state.selectedLocker = locker;
    },
    setToggleMapLocker(state: any, toggleMapLocker: number) {
      state.toggleMapLocker = toggleMapLocker;
    }
  },
  getters: {
    getAllLockers(state: any) {
      return state.allLockers;
    },
    getCurrentLockers(state: any) {
      return state.currentLockers;
    },
    getMerusoftLockers(state: any) {
      return state.merusoftLockers.map((l) => ({
        ...l,
        merusoftavailable: l.isavailable
      }));
    },
    getAvailableCurrentLockers(state: any) {
      return state.currentLockers.filter((s) => s.islocked && s.isavailable);
    },
    getLockerById: (state: any) => (id: string) => {
      const obj = state.allLockers?.filter((locker) => {
        return locker.id === id;
      })[0];
      return obj;
    },
    getToggleMapLocker: (state: any) => state.toggleMapLocker,
    selectedLocker: (s: any) => s.selectedLocker
  }
};
